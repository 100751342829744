import "./style.css"
import shortedNumber from "helpers/shortedNumber"
import Coins from './assets/coins-img.svg'
import BigCoins from './assets/big-coins-img.png'
import { TotalCount } from "components/TotalCount/TotalCount"
import Loader from "components/icons/Loader"

export const ReferralFarming = ({coins, clickHandler, text,btnText,language, loading}) => {
    return <div className = "referral__farming">
            <span className = "referral__farming-descr" style = {language !== "en" ? {fontWeight:'800'}: {}}>
                {text}
            </span>
            <div className = "referral__farming-block">
                <div className = "referral__farming-coins">
                <img src = {BigCoins} className = {"referral__farming-coins-img"} alt = ""/>
                <span className = {"referral__farming-coins-text"}>{shortedNumber(coins)}</span>
                </div> 
                <button className = "history-item__btn" onClick = {clickHandler}>
                    <span className="history-item__desc" style = {language !== "en" ? {fontWeight:'800'}: {}}> {loading ? <Loader fill="#582B02" width={20} height={20} />:btnText}</span>
                   {
                    !loading &&  <img
                    className="history-item__img-arrow"
                    src={Coins}
                    alt=""
                    />
                   }
                </button>
             </div>
    </div>
}