import "./style.css";
import { Roulette } from "components/Roulette/Roulette";
import { Gift } from "components/Gift/Gift";
import Close from "./assets/close.svg"
import { useState, useEffect } from "react";
import { TonButton } from "components/TonButton/TonButton";
import { useGlobalStore } from "stores/global";
import { getPageData } from "helpers/getPageData";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { getInfoWindowsConfig } from "helpers/getInfoWindowsConfig";
import { TON } from "entities/TON";
import { SuccessIcon } from "components/SuccessIcon/SuccessIcon";

export const ClaimTonPopup = ({ isOpen, onClose,data,webapp }) => {

    const [giftClicked, setGiftClicked] = useState(false)
    const [isSpinning, setIsSpinning] = useState(false)
    const [prize, setPrize] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [buttonText, setButtonText] = useState('')
    const [isSuccess, setIsSuccess] = useState(false)

    const pageData = getPageData('claim_ton_popup')
    const language = useGlobalStore(state => state.user_info.language);

    const chat_id = useGlobalStore(state => state.tg_info.chat_id);

    const {setGiftsHistory} = useGlobalStore()

    const api = useGlobalStore(state => state.api)

    const tonWalletData = getInfoWindowsConfig("ton_wallet")

    const {setPopup, setNotification, setGift} = useGlobalStore()

    const userFriendlyAddress = useTonAddress();
    const [tonConnectUI] = useTonConnectUI();

    const clickHandler = (e)=>{
        if(e.target instanceof SVGElement) {
            //clicked on gift 
            setTimeout(() => {
                setGiftClicked(true)
            }, 3000);
        }
    }

    useEffect(()=>{
        if(!giftClicked) {
            return
        }

        setIsSpinning(true)

        let timeout = setTimeout(() => {
            setIsSpinning(false)
            setPrize(data.amount)
        },22000)

        return () => {
            clearTimeout(timeout)
        }
    },[giftClicked])


    useEffect(()=>{
        if(!isSpinning) {
            return
        }

        setButtonText(pageData.opening[0][language])

        let timeout1 = setTimeout(() => {
            setButtonText(pageData.opening[1][language])
        },5000)

        let timeout2 = setTimeout(() => {
            setButtonText(pageData.opening[2][language])
        },10000)

        let timeout3 = setTimeout(() => {
            setButtonText(pageData.opening[3][language])
        },15000)


        return () => {
            clearTimeout(timeout1)
            clearTimeout(timeout2)
            clearTimeout(timeout3)
            
        }
    },[isSpinning])

    const successOperation =  () => {

        setIsSuccess(true)
        webapp.HapticFeedback.notificationOccurred('success')

    
    }


    const claimTon = async () =>{

        if(isSpinning) {
            return
        }

        setIsLoading(true)

        if(!userFriendlyAddress) {
            //open window with connect button
            const notification = {
                show: true,
                points: '',
                username: '',
                message: tonWalletData.not_connected_gift[language]
              
            }

            setTimeout(() => {
                setNotification(notification)
            }, 1000)

            setPopup(true)
            setIsLoading(false)
            return
            
        }

        //send transaction
        const tonWrapper = new TON(tonConnectUI, userFriendlyAddress);
        const transaction = await tonWrapper.sendTransaction(0.003)


        if(!transaction) {
                const notification = {
                    show: true,
                    points: '',
                    username: '',
                    message: tonWalletData.transaction_error[language]
                }
                setNotification(notification)
                setIsLoading(false)
                return
        }

        const claimGift = await api.claimUserGift(data.hash)

        if(!claimGift) {
            const notification = {
                show: true,
                points: '',
                username: '',
                message: tonWalletData.transaction_error[language]
            }
            setNotification(notification)
            setIsLoading(false)
            return
        }

        await updateGiftsHistory()
        successOperation()
        setIsLoading(false)


    }

    const updateGiftsHistory = async () => {
        const giftsHistory = await api.getGiftsHistory()

        if(!giftsHistory) {
            return
        }

        setGiftsHistory(giftsHistory)


    }

    const shareHandler = () => {
        const messageRu = `%0AПривет, я только что нашел ${data.amount} TON в сундуке в Klondike!. Присоединяйся в команду и зарабатывай вместе со мной!`
        const messageEn = "%0AHello, I just found " + data.amount + " TON in the chest in Klondike!. Join the team and make money together with me!"

        const link = `https://t.me/share/url?text=${language === "ru" ? messageRu : messageEn}&url=${process.env.REACT_APP_BOT_URL}?start=${chat_id}&space=true`
        webapp.openTelegramLink(link)
    }

    const onClosePopup = ()=>{
        if(isSuccess) {
            const newGift = {
                hash: null,
                amount: null,
                show:false
            }
    
            
                onClose()
                setTimeout(() => {
                    setGift(newGift)
                },300)

            return
        }

        onClose()
    }

    

   return <div className={`claim-ton-popup ${isOpen ? "claim-ton-popup-open" : ""} ${!giftClicked ? "flex": ""}`} onClick = {clickHandler}> 
        {(isOpen && !giftClicked) &&
            <Gift showOpenAnimation = {true} data = {{show:true}} showClaimPopup={false}/>
        }
        {
            giftClicked && <div className = "claim-ton-popup__roulette">
            {isSuccess ? 
            <>
            <div className = "success-icon"><SuccessIcon/></div> 
            <div className = "claim-ton-popup__share-btn">
            <span className = "claim-ton-popup__text-share">{pageData.share_text[language]}</span>
            <TonButton showTonIcon = {false} style = "klondike" text ={pageData.share_btn[language]} clickHandler = {shareHandler}/>
            </div>
            </>
            : 
            <>
            <Roulette winNumber = {data.amount} minWin = {0.001} maxWin = {0.1}/>
            <TonButton style = "klondike" text = {isSpinning? buttonText :`${pageData.claim[language]} ${prize > 0 ? prize : ""} TON`} disabled = {isSpinning} loading = {isLoading} clickHandler = {claimTon}/>
            </>
            }
            
        </div>
        }
        <div class="modal-close" onClick = {onClosePopup}>
            <img src = {Close} className = "close-icon"/>
        </div>
    </div>
}