import React from "react";
import "./style.css";
import HistoryItemImageTelegram from "./assets/Telegram-img.svg";
import HistoryItemImageArrow from "./assets/arrow-img.svg";
import { useNavigate } from "react-router-dom";
import shortedNumber from "helpers/shortedNumber";
import { useGlobalStore } from "stores/global";
import Loader from "components/icons/Loader";
import CoinsImg from "./assets/coins-img.svg"
import People from "./assets/People.svg"
import WebApp from "@twa-dev/sdk";
import { Notification } from "components/Notification/Notification";
import ConnectImg from "./assets/connect-img.svg"
import DiscordImg from "./assets/discord-img.svg"
import TwitterImg from "./assets/twitter-img.svg"
import { getInfoWindowsConfig } from "helpers/getInfoWindowsConfig";

export const HistoryItem = ({ text, price, status, taskType, taskId, href, itemIndex, language, pageData }) => {
  const [loading, setLoading] = React.useState(false);
  const [checking, setChecking] = React.useState(false);
  const [onStart, setOnStart] = React.useState(false);
  
  const api = useGlobalStore(state => state.api);

  const {setTasks, setUserInfo, setNotification,setPopup} = useGlobalStore();

  const twitter_connected = useGlobalStore(state => state.user_info.twitter_connected);

  const navigate = useNavigate();

  const defaultMsg = getInfoWindowsConfig("default")
  const claimMsg = getInfoWindowsConfig("claim")
  const subscriptionMsg = getInfoWindowsConfig("subscription")
  const completeTaskMsg = getInfoWindowsConfig("task_complete")
  const twitter_notification = getInfoWindowsConfig("twitter")


  if(taskType == 4 && status == 0 && twitter_connected && !onStart) {
    setOnStart(true)
  }

  const handleStart =  async () => {


    if(taskType == 0) {
      navigate("/team");
      return 
    } else if(taskType == 1 || taskType == 2 ) {
      WebApp.openTelegramLink('https://t.me/' + href)
      setOnStart(true)
      return
    } else if(taskType == 3 || taskType == 4) {
      navigate('/')
      setTimeout(() => {
        setPopup(true)
      }, 300);
      return
    } else if(taskType == 5) {
        if(!twitter_connected) {
          navigate('/')
          setTimeout(() => {
            setPopup(true)

            const notification = {
              show: true,
              points: '',
              username: '',
              message: twitter_notification.not_connected[language]
            }

            setNotification(notification)
          }, 300);

          return
        }

        setLoading(true)
        const startTask = await api.startTask()
        setLoading(false)
        if(!startTask) {
          const notification = {
            show: true,
            points: '',
            username: '',
            message: defaultMsg.error[language]
          }

          setNotification(notification)
          return
        }

        WebApp.openLink("https://x.com/"+href)

        setOnStart(true)
    }
  }

  const handleClaim = async () => {

    setLoading(true)

    const res = await api.claimRewards(taskId)

    setLoading(false)

    if(!res) {
      const notification = {
        show: true,
        points: '',
        username: '',
        message: defaultMsg.error[language]
      }

      setNotification(notification)

      return
    }

    const notification = {
      show: true,
      points: shortedNumber(price),
      username: '',
      message: claimMsg.success[language]
    }

    setNotification(notification)

    await updateTasks()

    
  }

  const handleCheck  = async () => {
      setChecking(true)

      const subscription = await api.checkSubscription(taskId)

      setChecking(false)

      setOnStart(false)

      if(!subscription) {
        // not subscribed. Show modal with error
        const notification = {
          show: true,
          points: '',
          username: '',
          message: subscriptionMsg.error[language]
        }

        setNotification(notification)
        return
      }

      const notification = {
        show: true,
        points: '',
        username: '',
        message: completeTaskMsg.success[language]
      }

      setNotification(notification)

      await updateTasks()
  }

  const updateTasks = async () => {

    const tasks = await api.getUserTasks()

    if(!tasks) {
      const notification = {
        show: true,
        points: '',
        username: '',
        message: defaultMsg.error[language]
      }

      setNotification(notification)
      return
    }

    await setTasks(tasks)
    await updateUserInfo()
  }

  const updateUserInfo = async () => {
    const userInfo = await api.getUserInfo();

    if(!userInfo) {

      const notification = {
        show: true,
        points: '',
        username: '',
        message: defaultMsg.error[language]
      }

      setNotification(notification)
      return
    }

    setUserInfo(userInfo)
  }

  return (
    <div className="history-item" key = {itemIndex}>
      <div className="history-item__container">
        {
          taskType == 0 &&
          <img
          className="history-item__img-telegram"
          src={People}
          alt=""
        />
        }
         {
          (taskType == 1 || taskType == 2) &&
          <img
          className="history-item__img-telegram"
          src={HistoryItemImageTelegram}
          alt=""
        />
        }
        {
          taskType == 3 &&
          <img
          className="history-item__img-telegram"
          src={ConnectImg}
          alt=""/>
        }
        {
          (taskType == 4 || taskType == 5) &&
          <img
          className="history-item__img-telegram"
          src={TwitterImg}
          alt=""/>
        }
        <div className="history-item__block">
          <span className="history-item__text">{text}</span>
          <span className="history-item__price">{shortedNumber(price)}</span>
        </div>
      </div>
      {
        (status == 0) ?
        !onStart ?
        <button className="history-item__btn" onClick = {handleStart}>
        <span className="history-item__desc" style = {language !== "en" ? {fontWeight:'800'}: {}}>{pageData.buttons.start[language]}</span>
        <img
          className="history-item__img-arrow"
          src={HistoryItemImageArrow}
          alt=""
        />
      </button>

      :
      <button className="history-item__btn" onClick = {handleCheck}>
        <span className="history-item__desc" style = {language !== "en" ? {fontWeight:'800'}: {}}>{checking ? <Loader fill="#582B02" width={20} height={20} /> : pageData.buttons.check[language]}</span>
      </button>

      :
      ''
      }

      {
        status == 1 && 
        <button className="history-item__btn" onClick = {handleClaim}>
        <span className="history-item__desc" style = {language !== "en" ? {fontWeight:'800'} : {}}>{ loading ? <Loader fill="#582B02" width={20} height={20} /> : pageData.buttons.claim[language] }</span>
        {!loading && <img
          className="history-item__img-arrow"
          src={CoinsImg}
          alt=""
        />}
      </button>
      }


    </div>
  );
};
