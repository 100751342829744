import animation from "./assets/animation.json";
import Lottie from "lottie-react";
import { useRef,useState, useEffect } from "react";
import { randomNumberInRange } from "helpers/randomNumberInRange";
import { randomFloatNumberInRange } from "helpers/randomFloatNumberInRange";
import WebApp from "@twa-dev/sdk";
import { ClaimTonPopup } from "components/ClaimTonPopup/ClaimTonPopup";
import "./style.css"
import chest from "./assets/chest.json"

export const Gift = ({data, showOpenAnimation, showClaimPopup})=>{

    const [position, setPosition] = useState({});
    const [shaking, setShaking] = useState(false);
    const [glowing, setGlowing] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [showGift, setShowGift] = useState(true);

    const lottieRef = useRef();

    const startOpenAnimate = () => {
        if(showOpenAnimation) {
            lottieRef.current.play()
            setShaking(true)
            setGlowing(true)
        } else {

                setIsOpen(true)
            
        }
    }

    const stopOpenAnimate = () => {
        lottieRef.current.stop()
        setShaking(false)
    }

    const calculateGiftPosition = () => {
        const Top = randomFloatNumberInRange(0.4,0.5);
        const position = Math.round(randomNumberInRange(0,1)) === 0 ? "left" : "right";

        const Left = position === 'left' ? randomFloatNumberInRange(-0.06, 0): randomFloatNumberInRange(0.55,0.65)

        const style = {
            top: `calc(var(--tg-viewport-stable-height)* ${Top})`,
            left: `calc(100vw* ${Left})`,
        }

        return style
        
    }

    const handleClick = () => {
        WebApp.HapticFeedback.notificationOccurred('success')
        startOpenAnimate()

       
    }

    const closePopup = () => {
        setIsOpen(false)
       
    }

    useEffect(() => {
        const style = calculateGiftPosition();
        setPosition(style);
    },[])

    const closeAndRemove = () => {
        closePopup()
    }

    if(!data.show) {
        return <></>
    }

    return <>
        {showClaimPopup && <ClaimTonPopup webapp = {WebApp} data = {data} isOpen = {isOpen} onClose = {() => closeAndRemove()}/>}
        {showGift && <div className = {`gift ${!shaking ? "shaking" : ""} ${glowing ? "glowing" : ""}`} style = {showClaimPopup ? position : {}} onClick = {handleClick}>
            <Lottie  lottieRef={lottieRef} autoplay = {false} animationData={chest} loop={false} />
        </div>}
        </>
}