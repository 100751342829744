import React from "react";
import { NavLink } from "react-router-dom";
import "./style.css";
import Arrow from "./assets/arrow.svg";

import { getLeaguesConfig } from "helpers/getLeaguesConfig";
import { useGlobalStore } from "stores/global";

export const LeagueLink = ({ link, balance }) => {

  const language = useGlobalStore(state => state.user_info.language);

  const leagues = getLeaguesConfig();
  let currentLeague = leagues.find(league => league.start <= balance && league.end >= balance);

  if(!currentLeague) {
    //user on the last league 
    currentLeague = leagues[leagues.length - 1];
  }

  return (
    <NavLink className="league-link" to={link}>
      <img className="league-link__img-shovel" src={currentLeague.img} alt="" />
      <span className="league-link__text" style = {language !== "en" ? {fontWeight: "1000"}:{}}>{currentLeague.title[language].split(" ")[0]}</span>
      <img className="league-link__img-arrow" src={Arrow} alt="" />
    </NavLink>
  );
};
