import Shovel1 from "components/LeagueSlider/assets/images/shovel.svg"
import Shovel2 from "components/LeagueSlider/assets/images/shovel2.svg";
import Shovel3 from "components/LeagueSlider/assets/images/shovel3.svg";
import Shovel4 from "components/LeagueSlider/assets/images/shovel4.svg";
import Shovel5 from "components/LeagueSlider/assets/images/shovel5.svg";
import Shovel6 from "components/LeagueSlider/assets/images/shovel6.svg";
import Shovel7 from "components/LeagueSlider/assets/images/shovel7.svg";
import Shovel8 from "components/LeagueSlider/assets/images/shovel8.svg";
import Shovel9 from "components/LeagueSlider/assets/images/shovel9.svg";
import Shovel10 from "components/LeagueSlider/assets/images/shovel10.svg";

import leaguesData from "config/leaguesData.json";

export const getLeaguesConfig = () => {
    const shovels = [Shovel1, Shovel2, Shovel3, Shovel4, Shovel5, Shovel6, Shovel7, Shovel8, Shovel9, Shovel10];

    const data = leaguesData.map((league, idx) => {
        return {
            ...league,
            img: shovels[idx]
        }
    })

    return data
}




