import ReactGA from "react-ga4";

export class Analytics {
    google;

    constructor() {
        if(Analytics.instance) {
            return Analytics.instance
        }
        this.google = ReactGA
        Analytics.instance = this
    }

    init() {
        this.google.initialize(process.env.REACT_APP_GA_TRACKING_ID)
    }

    trackPageView(page, title) {
        this.google.send({ hitType: "pageview", page: page, title: title });
    }

    trackEvent(category, action, label,value = 0) {
        this.google.event({
            category: category,
            action: action,
            label: label, 
            value: value
          });
    }

}