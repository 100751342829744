import WebApp from "@twa-dev/sdk";

export class CloudStorage {
    static instance;
    storage;

    constructor() {
        if(CloudStorage.instance) {
            return CloudStorage.instance
        }

        this.storage = WebApp.CloudStorage
        CloudStorage.instance = this
    }

    async getItem(key) {
        return new Promise((resolve, reject) => {
            this.storage.getItem(key, (error, result) => {
              if (error) {
                reject(error);
              } else {
                resolve(result);
              }
            });
          });
    }

    async setItem(key, value, expirationTime) {
        return new Promise((resolve, reject) => {
            this.storage.setItem(key, value, expirationTime, (error) => {
              if (error) {
                reject(error);
              } else {
                resolve();
              }
            });
        });
    }
    
}