import React from "react";
import "./style.css";
import { LevelItem } from "../LevelItem/LevelItem";

export const Levels = ({friends,language,pageData}) => {
  return (
    <div className="levels">
      <LevelItem numbers={friends[0]} desc={pageData.partners.level_1.title[language]} text={pageData.partners.level_1.text[language]} />
      <LevelItem numbers={friends[1]} desc={ pageData.partners.level_2.title[language]} text={ pageData.partners.level_2.text[language]} />
      <LevelItem numbers={friends[2]} desc={ pageData.partners.level_3.title[language]} text={ pageData.partners.level_3.text[language]} />
    </div>
  );
};
