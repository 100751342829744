import React, { useState } from "react";
import "./style.css";
import { Button } from "../Button/Button";
import { HistoryItem } from "../../components/HistoryItem/HistoryItem";
import { useGlobalStore } from "stores/global";
import { getPageData } from "helpers/getPageData";

export const HistoryTabs = ({tasks}) => {
  const [activeTab, setActiveTab] = useState(0);

  const language = useGlobalStore(state => state.user_info.language);

  const pageData = getPageData('tasks_page');

  const langIndex = {
    "en": 0,
    "ru": 1
  }

  const handleTabButtonClick = (index) => {
    setActiveTab(index);
  };


  const buttons = [pageData.active[language], pageData.completed[language]];



  const activeTasks = tasks.filter((task) => task.status_id < 2).sort((a, b) => {
    const statusA = a.status_id;
    const statusB = b.status_id;
  
    if (statusA === 1) {
      return -1;
    } else if (statusB === 1) {
      return 1;
    } 
  });

 

  const completedTasks = tasks.filter((task) => task.status_id == 2);

  const contents = [
    activeTasks,
    completedTasks
  ];

  return (
    <div className="history-tabs">
      <div className="history-tabs__btns">
        {buttons.map((button, index) => (
          <Button
            text={button}
            key={index}
            active={activeTab === index}
            handleClick={() => handleTabButtonClick(index)}
          />
        ))}
      </div>
      <div className="history-tabs__contents">

    
        {contents.map((content, index) => (
          <div
            className={`history-tabs__content ${
              activeTab === index ? "active" : ""
            }`}
            key={index}
          >
            {content.map((task, itemIndex) => {

              console.log(task)
              return <HistoryItem
                key={itemIndex}
                price={task.task.cost}
                text={task.task.name.split("/")[langIndex[language]] + `${task.task.task_type == 0 ? ` (${task.task.repetition_count - task.repetitions_left}/${task.task.repetition_count})`:``}`}
                status = {task.status_id}
                taskType = {task.task.task_type}
                taskId = {task.task.id}
                href = {task.task.href}
                language = {language}
                pageData = {pageData}
              />
            })}
          </div>
        ))}
      </div>
    </div>
  );
};
