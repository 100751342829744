import React, {useEffect,useState} from "react";
import "./style.css";
import Cross from "./assets/button-Cross.svg";
import { useTonConnectModal, useTonWallet, useTonAddress,useIsConnectionRestored, useTonConnectUI } from '@tonconnect/ui-react';
import { BigButton } from "../BigButton/BigButton";
import Wallet from "./assets/popup-img__wallet.svg";
import Twitter from "./assets/popup-img__twitter.svg";
import Discord from "./assets/popup-img__discord.svg";
import formatAddress from "helpers/formatAddress";
import { useGlobalStore } from "stores/global";
import WebApp from "@twa-dev/sdk";
import { getPageData } from "helpers/getPageData";
import { getInfoWindowsConfig } from "helpers/getInfoWindowsConfig";

export const Popup = ({ isOpen, onClose, img, text, onWalletConnect = false }) => {

  const [firstTimeConnection, setFirstTimeConnection] = useState(false);

  const [tonConnectUI] = useTonConnectUI();

  const {setTasks} = useGlobalStore();


  const { state, open, close } = useTonConnectModal();

  const {setNotification} = useGlobalStore();

  const api = useGlobalStore(state => state.api);

  const telegram_id = useGlobalStore(state => state.tg_info.chat_id);
  const tasks = useGlobalStore(state => state.tasks);
  const twitter_connected = useGlobalStore(state => state.user_info.twitter_connected);

  const language = useGlobalStore(state => state.user_info.language);

  const pageData = getPageData('profile');
  const windowDataTwitter = getInfoWindowsConfig('twitter')
  const windowDataTon = getInfoWindowsConfig('ton_wallet');


  const userFriendlyAddress = useTonAddress();
  const rawAddress = useTonAddress(false);

  const openConnectModal = () => {
    if(!rawAddress) {
        open();
        setFirstTimeConnection(true);
    }
  }

  const updateTasks = async () => {

    const tasks = await api.getUserTasks()

    await setTasks(tasks)
  }

  const authenticateTwitter = async () => {
      if(!twitter_connected) {
        const authTwitter = await api.authTwitter()

        if(!authTwitter) {
          const notification = {
            show: true,
            points: '',
            username: '',
            message: windowDataTwitter.error[language]
          }

          setNotification(notification)
          return
        }

        WebApp.openLink(authTwitter.authorization_url)

        
        WebApp.close()
        
      }

      
  }

  useEffect(() => {
    const fn = async () => {
      if(rawAddress && firstTimeConnection) {
        //send request to endpoint to save user address
        
        const authTonWallet = await api.authTonWallet(telegram_id, userFriendlyAddress)

        if(!authTonWallet) {
          const notification = {
            show: true,
            points: '',
            username: '',
            message: windowDataTon.error[language]
          }

          setNotification(notification)

          tonConnectUI.disconnect()
          return
        }

        const connectWalletTask = tasks.find(task=> task.status_id == 0 && task.task.task_type == 3)


        if(connectWalletTask) {
          const checkSubscription = await api.checkSubscription(connectWalletTask.task_id)

          if(!checkSubscription) {
            const notification = {
              show: true,
              points: '',
              username: '',
              message: windowDataTon.error[language]
            }

            setNotification(notification)

            tonConnectUI.disconnect()
            return
          }

          updateTasks()
        }

        if(onWalletConnect) {
          onWalletConnect()
          onClose()
        }




      
        
      }
    }

    fn()

  },[rawAddress])

 

  return (
    <div className={`popup ${isOpen ? "popup-open" : ""}`}>
      <div className="popup-content">
        <button className="popup-close" onClick={onClose}>
          <img className="popup__btn" src={Cross} alt="" />
        </button>
        <img className="popup__img" src={img} alt="" />
        <span className="popup__text">{text}</span>
        <div className="popup__block">
          <BigButton type = "connect" img={Wallet} text={rawAddress.length > 0 ? formatAddress(userFriendlyAddress): pageData.connect_wallet_btn.not_active[language]} handleClick = {openConnectModal} connected = {rawAddress.length > 0}/>
          <BigButton language = {language} img={Twitter} text={twitter_connected ? pageData.connect_twitter_btn.active[language] : pageData.connect_twitter_btn.not_active[language]} connected = {twitter_connected} handleClick = {authenticateTwitter}/>
          {/* <BigButton img={Discord} text="connect discord" /> */}
        </div>
      </div>
    </div>
  );
};
