import React, { useState } from "react";
import "./style.css";
import ShortCell from "../ShortCell/ShortCell";
import Cup from "./assets/img/solar_cup.svg";
import { Popup } from "../Popup/Popup";
import anonimous from "./assets/img/anonimous.png";



export const HeaderCells = ({image,text,rank}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);


  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="header-cells">
      <ShortCell to="/profile" image={image ? image : anonimous} avatar = {true} text={text} onClick={openPopup}/>
      <ShortCell image={Cup} text={rank} />
      <Popup
        isOpen={isPopupOpen}
        onClose={closePopup}
        img={image}
        text={text}
      />
    </div>
  );
};
