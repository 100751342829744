import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "normalize.css";
import "./assets/fonts/fonts.css";
import "./app.css";
import LeagueDescription from "../pages/LeagueDescription/LeagueDescription";
import Loading from "../components/Loading/Loading";
import Navigation from "../components/Navigation/Navigation";
import WebApp from "@twa-dev/sdk";
import { useGlobalStore } from "../stores/global";
import { useGameStore } from "stores/game";
import Home from "pages/Home/Home";
import Team from "pages/Team/Team";
import Tasks from "pages/Tasks/Tasks";
import RoadMap from "pages/Roadmap/RoadMap";
import { Farm } from "pages/Farm/Farm";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { API } from "entities/api";
import { Notification } from "components/Notification/Notification";
import { Analytics } from "entities/analytics";
import { Tavern } from "pages/Tavern/Tavern";
import { WhackEmAllGame } from "components/WhackEmAllGame/WhackEmAllGame";
import { Gifts } from "pages/Gifts/Gifts";
import { BackButton } from '@twa-dev/sdk/react';
import { useLocation } from "react-router-dom";










const App = () => {

  const [tonConnectUI, setOptions] = useTonConnectUI();

  const { setTgInfo, setApi, setUserInfo, setTasks, setFarmInfo, setLoaded, setGoogle, setGift,setGiftsHistory } = useGlobalStore();

  const {setBanditsGameAttempts} = useGameStore();

  const notification = useGlobalStore(state => state.notification);

  const banditsGameStarted = useGameStore(state => state.banditsGameStarted);

  const loaded = useGlobalStore(state => state.loaded);

  const {setNotification} = useGlobalStore();

  const location = useLocation();





  useEffect(() => {
      const fn = async () => {
        
       if(loaded) {
        return
       }

        if(!WebApp.initData) {
          return 
        }

        WebApp.ready();
      
        if(!WebApp.isExpanded) {
          WebApp.expand()
        }


        const api = new API(WebApp.initDataUnsafe.user.id);

        const [userInfo, userTasks, farmInfo, banditsGameAttempts,giftsHistory] = await Promise.all(
          [
            api.getUserInfo(),
            api.getUserTasks(),
            api.getFarmInfo(),
            api.getAttemptsLeft(1),
            api.getGiftsHistory()
          ]
        );


       
        

        if(!userInfo) {
          const notification = {
            show: true,
            points: '',
            username: '',
            message: 'Failed to fetch user info. Please try again later'
          }

          setNotification(notification)
          return
        }


        if(!userTasks) {
          const notification = {
            show: true,
            points: '',
            username: '',
            message: 'Failed to fetch user tasks. Please try again later'
          }

          setNotification(notification)
          return
        }




          if(!farmInfo) {
            const notification = {
              show: true,
              points: '',
              username: '',
              message: 'Failed to fetch user info. Please try again later'
            }
  
            setNotification(notification)
            return
          }


          if(!banditsGameAttempts) {
            const notification = {
              show: true,
              points: '',
              username: '',
              message: 'Failed to fetch user info. Please try again later'
            }
  
            setNotification(notification)
            return
          }

          if(!giftsHistory) {
            const notification = {
              show: true,
              points: '',
              username: '',
              message: 'Failed to fetch user info. Please try again later'
            }
  
            setNotification(notification)
            return
          }


          if(userInfo.hasGifts) {
            const userGift = await api.getUserGift()

            if(userGift) {
              await setGift(userGift)
            }
          }


          await setFarmInfo(farmInfo);
          await setBanditsGameAttempts(banditsGameAttempts.attempts);
        

          const analytics = new Analytics();
          analytics.init();


          await setGoogle(analytics)


        await setUserInfo(userInfo);
        await setTasks(userTasks);
        await setTgInfo(WebApp.initDataUnsafe.user);
        await setApi(api);
        await setGiftsHistory(giftsHistory)
        
       await setLoaded(true)
        setOptions({ 
          language: userInfo.language 
        })

        
      }

      fn()
  }, []);


  if(!WebApp.isExpanded) {
    return
  }

  if(!loaded) {
    return <div className="app-container">
          <Loading />
        
        </div>
  }

  if(banditsGameStarted) {
    return <>
    {notification.show && (
          <Notification
            points={notification.points}
            username={notification.username}
            message={notification.message}
            onClose={() => {
              setNotification({ ...notification, show: false });
            }}
          />
        )}
    <WhackEmAllGame />
    </>
  }

  return (
    <>
      {location.pathname !== "/" && <BackButton onClick={() => window.history.back()} />}
      <div className="app-container" >
      {notification.show && (
          <Notification
            points={notification.points}
            username={notification.username}
            message={notification.message}
            onClose={() => {
              setNotification({ ...notification, show: false });
            }}
          />
        )}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/team" element={<Team />} />
          <Route path="/roadmap" element={<RoadMap />} />
          <Route path="/league-description" element={<LeagueDescription />} />
          <Route path = "/farm" element = {<Farm/>}/>
          <Route path = "/bar" element = {<Tavern/>}/>
          <Route path = "/gift" element = {<Gifts/>}/>
        </Routes>
        <Navigation />
        
    
      </div>
    </>
  );
};

export default App;


