import "./style.css";
import { useEffect, useState,useMemo } from "react";
import { randomFloatNumberInRange } from "helpers/randomFloatNumberInRange";
import { randomNumberInRange } from "helpers/randomNumberInRange";
import Toncoin from "./assets/ton-no-back.png"

export const Roulette = ({winNumber, minWin,maxWin}) => {

    const [wheelStyles, setWheelStyles] = useState({});

    const wheel = useMemo(() => {

        const _wheel = [];

        _wheel.push(randomNumberInRange(1,3))
  
        for(let i =0; i < 12; i++){
          const randNum = randomFloatNumberInRange(minWin, maxWin);
          _wheel.push(
              roundNumber(randNum)
          )
        }
        _wheel.push(winNumber)

        
        _wheel.push(randomNumberInRange(1,3))
       
    
        return shuffleArray(_wheel)
        
      },[])


    const spinWheel =() =>{
        const order = transformArray(wheel)
        const position = order.indexOf(winNumber);
                  
        //determine position where to land
        const rows = 12
        const card = 75 + 3 * 2
        let landingPosition = (rows * 15 * card) + (position * card);
            
        const randomize = Math.floor(Math.random() * 75) - (75/2);
          
        landingPosition = landingPosition + randomize;
          
        const object = {
              x: Math.floor(Math.random() * 50) / 100,
          y: Math.floor(Math.random() * 20) / 100
          };

        setWheelStyles({
            transitionTimingFunction:'cubic-bezier(0,'+ object.x +','+ object.y + ',1)',
            transitionDuration:'20s',
            transform:'translate3d(-'+landingPosition+'px, 0px, 0px)'
        })
        
        setTimeout(function(){
            let resetTo = -(position * card + randomize);
            setWheelStyles({
                transitionTimingFunction:'',
                transitionDuration:'',
                transform:'translate3d(-'+resetTo+'px, 0px, 0px)'
            });
        }, 20 * 1000);
      }

    useEffect(() => {
       let spin = setTimeout(() => {
            spinWheel()
        }, 530);

        return () => {
            clearTimeout(spin)
        }
    },[wheel])


    return <div class='roulette-wrapper'>
    <div class='selector'></div>
    <div class='wheel' style = {wheelStyles} onClick = {spinWheel}>
        {
            [...Array(29)].map((_, index) => {
                return <div className = "row">
                    
                        {
                            wheel.map((item, index) => {
                                return <div className = "card blue">
                                        <img src = {Toncoin}/>
                                        <span className = "card__text">{item}</span>
                                    </div>
                            })
                        }
                    
                </div>
            })
        }
        
    </div>
  </div>
}

function roundNumber(num) {
        const numStr = num.toString();
        const dotIndex = numStr.indexOf('.');
        if (dotIndex !== -1) {
          const fracPart = numStr.substring(dotIndex + 1);
          if (fracPart.length > 3) {
            return Number(num.toFixed(3));
          }
        }
        return num;
}

function transformArray(arr) {
   const firstSlice = arr.slice(7,15)
    const secondSlice = arr.slice(0,7)

    return [...firstSlice,...secondSlice]
   
}

function shuffleArray(arr) {
    return arr.sort(() => Math.random() - 0.5);
  }