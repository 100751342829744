import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

export class Utils {
    vector;
    constructor(userId) {
        this.vector = userId
    }
    

    encryptSimpleClaim(points, gameHash) {
        const jwt = Cookies.get('access_token')

        const jwtHash = this.generateSHA256Hash(jwt)

        const vectorHash = this.generateSHA256Hash(this.vector.toString())

        const encryptedMsg = this.encryptAES(gameHash + ":" + points.toString(), jwtHash, vectorHash)

        return encryptedMsg

    }

    encryptTransactionClaim(gameHash,points, wallet) {

        const data = gameHash + ":" + points
        const hashedWalletAddress = this.generateSHA256Hash(wallet)

        const vectorHash = this.generateSHA256Hash(this.vector.toString())

        const encryptedMsg = this.encryptAES(data, hashedWalletAddress, vectorHash)

        return encryptedMsg
        
    }

    decryptTonGift(encryptedMsg,key) {

        const keyHash = this.generateSHA256Hash(key)
        const vectorHash = this.generateSHA256Hash(this.vector.toString())

        const decryptedMsg = this.decryptAES(encryptedMsg, keyHash, vectorHash)

        

        return decryptedMsg
        
    }

    generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    generateSHA256Hash(input) {
        const hash = CryptoJS.SHA256(input);
        return hash.toString(CryptoJS.enc.Hex);
    }

    encryptAES(input, key, vector) {
        const keyWordArray = CryptoJS.enc.Hex.parse(key);
        const ivWordArray = CryptoJS.enc.Hex.parse(vector);

        const encrypted = CryptoJS.AES.encrypt(input, keyWordArray, {
            iv: ivWordArray,
            mode: CryptoJS.mode.CBC, 
            padding: CryptoJS.pad.Pkcs7  
        });

        return encrypted.toString();
    }

    decryptAES(input, key, vector) {
        const keyWordArray = CryptoJS.enc.Hex.parse(key);
        const ivWordArray = CryptoJS.enc.Hex.parse(vector);
      
        const decrypted = CryptoJS.AES.decrypt(input, keyWordArray, {
          iv: ivWordArray,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });
      
        return decrypted.toString(CryptoJS.enc.Utf8);
      }
}