import { Compass } from "components/Compass/Compass";
import AppWidthWithContainer from "components/AppWidthContainer/AppWidthWithContainer";
import { BigButton } from "components/BigButton/BigButton";
import { ProgressBar } from "components/ProgressBar/ProgressBar";
import Shovel from './assets/shovel.svg'
import { useState, useEffect } from "react";
import { getPageData } from "helpers/getPageData";
import { useGlobalStore } from "stores/global";
import { getInfoWindowsConfig } from "helpers/getInfoWindowsConfig";
import shortedNumber from "helpers/shortedNumber";
import { remainedTime } from "helpers/remainedTime";
import Coins from './assets/coins-img.svg'
import Loader from "components/icons/Loader";


import './style.css'

export const Farm = ()=> {

    const [userFarmInfo, setUserFarmInfo] = useState({
        status: 0,
        time_left: 0,
        coins_farmed: 0,
        total_coins: 0,
    })
    const [status, setStatus] = useState(0)
    const [farmLoaded, setFarmLoaded] = useState(false)
    const [loading, setLoading] = useState(false)

    const language = useGlobalStore(state => state.user_info.language);

    const google = useGlobalStore(state => state.google);

    const api = useGlobalStore(state => state.api);

    const tg_info = useGlobalStore(state => state.tg_info);

    const { setFarmInfo, setNotification, addUserBalance} = useGlobalStore();

    const farm = useGlobalStore(state => state.farm);


    const pageData = getPageData("farm_page")
    const defaultMsg = getInfoWindowsConfig("default")
    const claimMsg = getInfoWindowsConfig("claim")


    useEffect(()=>{

        google.trackPageView('/farm', 'Farm');

        if(farm.status !== 1) {
            setUserFarmInfo({
                ...farm
            })
            return
        }

        const loaded_at = farm.loaded_at
        const now = Math.floor(Date.now() / 1000)


        if(now - loaded_at > farm.time_left) {

            setUserFarmInfo({
                ...farm,
                status:2,
                time_left: 0,
                coins_farmed: farm.total_coins
            })
            
            setFarmInfo({
                ...farm,
                status: 2
                
            })

        } else {
            const coinsPerSecond = (farm.total_coins - farm.coins_farmed) / (farm.time_left)
            const elapsed = now - loaded_at

            setUserFarmInfo({
                ...farm,
                time_left: farm.time_left - elapsed,
                coins_farmed: farm.coins_farmed + coinsPerSecond * elapsed
            })

            setFarmLoaded(true)
        }
        

    },[])

    useEffect(()=>{
        if(status == 2) {
            setFarmInfo({
                ...farm,
                status: 2
                
            })
        }
    },[status])




    useEffect(()=>{

        let interval
        if(userFarmInfo.status == 1) {
            interval = setInterval(() => {
                if(userFarmInfo.time_left<=1) {
                    setStatus(2)
                    setUserFarmInfo(prev=>({...prev, status: 2}))
                    clearInterval(interval)
                    return
                }
                const coinsPerSecond = (userFarmInfo.total_coins - userFarmInfo.coins_farmed) / (userFarmInfo.time_left);
    
                
                setUserFarmInfo(prev=>{

                    if(prev.time_left <= 1) {
                   
                        setStatus(2)
                        clearInterval(interval)
    
                    }

                    return {
                        ...prev,
                        status: prev.time_left == 1 ? 2 : prev.status,
                        coins_farmed: prev.coins_farmed + coinsPerSecond,
                        time_left: prev.time_left - 1
                    }
                })

            }, 1000);
        } else {
            if (interval) clearInterval(interval)
        }

        return () => {
            if(interval) clearInterval(interval)
        }
    },[farmLoaded])


    const startFarming =async  ()=>{


        setLoading(true)

        const startFarm = await api.startFarming()

        setLoading(false)

        if(!startFarm) {
            const notification = {
                show: true,
                points: '',
                username: '',
                message: defaultMsg.error[language]
            }

            setNotification(notification)
            return
        }

        google.trackEvent('farm', 'start farming', `chat_id: ${tg_info.chat_id}`);

        setFarmInfo(startFarm)
        setUserFarmInfo(startFarm)
        setFarmLoaded(true)
        
       
    }

    const claimRewards = async ()=>{

        setLoading(true)

        const claimFarming = await api.claimFarming()

        setLoading(false)

        if(!claimFarming) {
            const notification = {
                show: true,
                points: '',
                username: '',
                message: defaultMsg.error[language]
            }

            setNotification(notification)
            return
        }

        google.trackEvent('farm', 'claim rewards', `chat_id: ${tg_info.chat_id}, amount: ${claimFarming.amount}`);

        const notification = {
            show: true,
            points: claimFarming.amount,
            username: '',
            message: claimMsg.success[language]
        }

        const newFarm = {
            status: 0,
            time_left: 0,
            coins_farmed: 0,
            total_coins: 0,
            loaded_at: Math.floor(Date.now() / 1000)
        }

        setNotification(notification)
        setFarmInfo(newFarm)
        setUserFarmInfo(newFarm)
        addUserBalance(claimFarming.amount)
        
    }


    return (
        <AppWidthWithContainer >
          <div className="farm__content">
            <div>
            <Compass animate = {userFarmInfo.status == 1}/>
            </div>
            {userFarmInfo.status !== 1 && <div >

            </div>}
            
            {userFarmInfo.status == 1 && <div style = {language == "ru"?{fontWeight:"800"}:{}} className="farm__content-text" >
                <span >
                {pageData.profit[language]}: 
                </span>
                <span>{shortedNumber(userFarmInfo.coins_farmed)}</span>
            </div>}
            
            <div className="farm__content-start-farm" style = {userFarmInfo.status == 1 ? {width: `100%`} : {}}>
            {
                userFarmInfo.status == 1 && <ProgressBar farm = {true} timer  ={remainedTime(userFarmInfo.time_left)} width = {(userFarmInfo.coins_farmed / userFarmInfo.total_coins)}/>
            }
            {
                userFarmInfo.status == 0 && <button className = {"farm__content-start-farm-button"} onClick = {startFarming}>
                <span className="history-item__desc" style = {language == "ru"?{fontWeight:"800"}:{}}>{loading ? <Loader fill="#582B02" width={20} height={20} /> : pageData.button.start[language]}</span>
                 {
                    !loading && <img
                    className="history-item__img-arrow"
                    src={Shovel}
                    alt=""
                    />
                 }
             </button>
            }
            {
                userFarmInfo.status == 2 && <button onClick = {claimRewards} className = {"farm__content-start-farm-button"} >
                <span className="history-item__desc" style = {language == "ru"?{fontWeight:"800"}:{}}>{ loading ? <Loader fill="#582B02" width={20} height={20} /> :pageData.button.claim[language] + ` ${shortedNumber(userFarmInfo.total_coins)}`}</span>
                 {
                    !loading && <img
                    className="history-item__img-arrow"
                    src={Coins}
                    alt=""
                    />
                 }
             </button>
            }
            </div>
          </div>
        </AppWidthWithContainer>
      );
}