import React from "react";
import "./style.css";
import NavigationItem from "../NavigationItem/NavigationItem";
import { useLocation } from "react-router-dom";

import shovel from "./assets/shovel.svg";
import tasks from "./assets/tasks.svg";
import team from "./assets/team.svg";
import map from "./assets/map.svg";
import bar from "./assets/bar.svg"
import Container from "../Container/Container";

import { useGlobalStore } from "stores/global";
import { getPageData } from "helpers/getPageData";

function Navigation() {
  const location = useLocation();

  const language = useGlobalStore(state => state.user_info.language);

  const pageData = getPageData('footer_menu');

  const isHome = location.pathname === "/";

  return (
    <nav className={`bottom-nav ${isHome ? "bottom-nav--dark" : ""}`}>
      <Container className="bottom-nav__container" >
        <NavigationItem path={"/"} icon={shovel}></NavigationItem>
        <NavigationItem
          path={"/tasks"}
          icon={tasks}
          text={pageData.task_btn[language]}
        ></NavigationItem>
        <NavigationItem
          path={"/team"}
          icon={team}
          text={pageData.team_btn[language]}
        ></NavigationItem>
        <NavigationItem
          path={"/roadmap"}
          icon={map}
          text={pageData.roadmap_btn[language]}
        ></NavigationItem>
        <NavigationItem
          path={"/bar"}
          icon={bar}
          text={pageData.bar_btn[language]}
        ></NavigationItem>
      </Container>
    </nav>
  );
}

export default Navigation;
