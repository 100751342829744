import React from "react";
import "./style.css";
import { getPageData } from "helpers/getPageData";

export const ProgressBar = ({ start, end, current,language, lastLeaguePoints, farm = false, width, timer }) => {
 
  if(farm) {
    return (
      <div className="progress-block">
        <span>
          {timer}
        </span>
        <div className="progress-bar">
          <div className="progress-bar__line">
            <span
              className="progress-bar__left"
              style={{ width: `${(width) * 100}%` }}
            ></span>
            <span
              className="progress-bar__circle"
              style={{
                left: `${
                  (width) * 100 > 3 && (width) * 100 < 97
                    ? `calc(${(width) * 100}% - 14px)`
                    : (width) * 100 > 97
                    ? "auto"
                    : "0"
                }`,
  
                right: `${(width) * 100 > 97 ? "0" : "auto"}`,
              }}
            ></span>
            <span className="progress-bar__right"></span>
          </div>
        </div>
      </div>
    );
  }

  const pageData = getPageData('leagues_page');

  const currentWidth = current < start ? 0 : current > end ? 1 : (current / end);

  const formatter = new Intl.NumberFormat("ru-RU")

  if( currentWidth === 1 || currentWidth === 0) {
    return <div className="progress-block">
      <center>
      <span>
        {pageData.from[language]} {formatter.format(start)}
      </span>
      </center>
      </div>;
  }

  if(typeof end == "string" ) {
    if(current >= lastLeaguePoints) {
      return <div className="progress-block">

      <center>
      <span>
        {pageData.last_league[language]}
      </span>
      </center>
      </div>
    } else {
      return <div className="progress-block">
      <center>
      <span>
        {pageData.from[language]} {formatter.format(start)}
      </span>
      </center>
      </div>;
    }
  }


  return (
    <div className="progress-block">
      <span>
        {formatter.format(current)} / {formatter.format(end)}
      </span>
      <div className="progress-bar">
        <div className="progress-bar__line">
          <span
            className="progress-bar__left"
            style={{ width: `${(currentWidth) * 100}%` }}
          ></span>
          <span
            className="progress-bar__circle"
            style={{
              left: `${
                (currentWidth) * 100 > 3 && (currentWidth) * 100 < 97
                  ? `calc(${(currentWidth) * 100}% - 14px)`
                  : (currentWidth) * 100 > 97
                  ? "auto"
                  : "0"
              }`,

              right: `${(currentWidth) * 100 > 97 ? "0" : "auto"}`,
            }}
          ></span>
          <span className="progress-bar__right"></span>
        </div>
      </div>
    </div>
  );
};
