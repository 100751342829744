import React from "react";
import "./style.css";
import Title from "../Title/Title";

import CoinsIcon from "./assets/coins.png";

export const TotalCount = ({count, style}) => {
  return (
    <div className="total-count" style = {{...style}}>
      <img src={CoinsIcon} alt="" />
      <Title text={count} />
    </div>
  );
};
