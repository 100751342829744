import "./style.css";
import { useEffect,useState } from "react";
import { useGlobalStore } from "stores/global";
import { useGameStore } from "stores/game";
import { HeaderCells } from "components/HeaderCells/HeaderCells";
import { getTavernTablesData } from "helpers/getTavernTablesData";
import Lock from "./assets/lock.png";
import { GamePopup } from "components/GamePopup/GamePopup";
import { getGameRulesData } from "helpers/getGameRulesData";
import { useNavigate } from "react-router-dom";
import { getInfoWindowsConfig } from "helpers/getInfoWindowsConfig";

export const Tavern = () => {

    const [gamePopupData, setGamePopupData] = useState({
        isOpen: false,
        title: "",
        rulesData: [],
        attempts: 0
    });

    const [Loading, setLoading] = useState(false);

    const tg_info = useGlobalStore(state => state.tg_info );
    const avatar = useGlobalStore(state => state.user_info.avatar );
    const rank = useGlobalStore(state => state.user_info.rank );
    const language = useGlobalStore(state => state.user_info.language );
    const api = useGlobalStore(state => state.api );

    const banditsGameAttempts = useGameStore(state => state.banditsGameAttempts );

    const {setBanditsGameStarted,setBanditsGameHash} = useGameStore();

    const {setNotification} = useGlobalStore();



    const tables = getTavernTablesData(["",Lock,Lock,Lock,Lock,Lock]);

    const noAttemptsMsg = getInfoWindowsConfig('game');
    const defaultMsg = getInfoWindowsConfig('default');
    

    useEffect(() => {
       const bottomNav = document.querySelector(".bottom-nav");
       bottomNav.classList.add("bottom-nav--dark");
       
       return () => {
           bottomNav.classList.remove("bottom-nav--dark");
       }
    },[])

    const onGamePopupClose = () => {
        setGamePopupData(prev => ({...prev, isOpen: false}));
    }

    const openGamePopup = (table) => {
        if(table.name === "regular table") return

        const gameRulesData = getGameRulesData(table.name);

        setGamePopupData(prev => ({
            ...prev, 
            isOpen: true, 
            title: table.name,
            rulesData: gameRulesData,
            attempts: banditsGameAttempts
        }));
    }

    const startGameHandler = async ()=>{
        if(gamePopupData.title === "Bandits invasion"){
            if(banditsGameAttempts == 0) {
                const notification = {
                    show: true,
                    points: '',
                    username: '',
                    message: noAttemptsMsg.no_attempts[language]
                }

                setNotification(notification);
                return
            }

            setLoading(true);
            const gameHash = await api.startGame(1)
            setLoading(false);

            if(!gameHash) {
                const notification = {
                    show: true,
                    points: '',
                    username: '',
                    message: defaultMsg.error[language]
                }

                setNotification(notification);

                return
            }

            setBanditsGameHash(gameHash);



            setBanditsGameStarted(true);
        }
    }

    


    return <div className = "tavern">
        <GamePopup loading = {Loading} gameHandler = {startGameHandler} attempts = {gamePopupData.attempts} language = {language} rulesData = {gamePopupData.rulesData} isOpen = {gamePopupData.isOpen} title = {gamePopupData.title} onClose = {onGamePopupClose}/>
       <div className = "tavern__profile">
            <HeaderCells image = {avatar} text = {tg_info.tg_name} rank = {rank}/>
       </div>
       <div className = "tavern__tables" style = {{gridColumnGap: "10px",gridRowGap: "calc(var(--tg-viewport-stable-height) * 0.07)", marginBottom:"calc(var(--tg-viewport-stable-height) * 0.04)" }}>
            {
                tables.map((item) => {
                    return <div className = "tavern__table" key = {item.id} onClick = {()=> openGamePopup(item)}>
                        { item.img &&<img src = {item.img}  alt = {item.name}/>}
                    </div>
                })
            }
       </div>
    </div>
}