import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "./style.css";
import { getLeaguesConfig } from "helpers/getLeaguesConfig";

import { ProgressBar } from "../ProgressBar/ProgressBar";
import { useGlobalStore } from "stores/global";

export const LeagueSlider = () => {
  const data = getLeaguesConfig();

  const language = useGlobalStore(state => state.user_info.language);
  const balance = useGlobalStore(state => state.user_info.balance);

  let currentLeague = data.find(league => league.start <= balance && league.end >= balance);

  const lastLeaguePoints = data[data.length - 1].start

  if(!currentLeague) {
    //user on the last league 
    currentLeague = data[data.length - 1];
  }


  return (
    <Swiper
      modules={[Navigation]}
      navigation
      spaceBetween={50}
      slidesPerView={1}
      loop={true}
      initialSlide = {data.indexOf(currentLeague)}
    >
      {data.map((slide, idx) => {
        return (
          <SwiperSlide key={idx}>
            <span className="league-slider__title">{slide.title[language]}</span>
            <p className="league-slider__text">{slide.text[language]}</p>
            <img className="league-slider__img" src={slide.img} alt="" />

            <ProgressBar language = {language} start={slide.start} end={slide.end} lastLeaguePoints = {lastLeaguePoints} current = {balance} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
