import React from "react";
import AppWidthWithContainer from "../../components/AppWidthContainer/AppWidthWithContainer";
import Title from "../../components/Title/Title";
import { InformationBox } from "../../components/InformationBox/InformationBox";
import { Copylink } from "../../components/Copylink/Copylink";
import { Partners } from "../../components/Partners/Partners";
import { Levels } from "../../components/Levels/Levels";
import { useGlobalStore } from "stores/global";
import { getPageData } from "helpers/getPageData";
import { ReferralFarming } from "components/ReferralFarming/ReferralFarming";
import { getInfoWindowsConfig } from "helpers/getInfoWindowsConfig";


import "./style.css";

const Team = () => {

  const [loading, setLoading] = React.useState(false);

  const tg_info = useGlobalStore(state => state.tg_info );
  const friends = useGlobalStore(state => state.user_info.friends );
  const referral_balance = useGlobalStore(state => state.user_info.referral_balance );

  const google = useGlobalStore(state => state.google );

  const api = useGlobalStore(state => state.api );

  const language = useGlobalStore(state => state.user_info.language);

  const {setNotification, addUserBalance, resetReferralBalance} = useGlobalStore();

  const pageData = getPageData('team_page');
  const defaultMsg = getInfoWindowsConfig('default');
  const claimMsg = getInfoWindowsConfig('claim');

  React.useEffect(() => {
    google.trackPageView('/team', 'Team');
  },[])

  const claimRefRewards = async () => {

    if(referral_balance == 0) {
      const notification = {
        show: true,
        points: '',
        username: '',
        message: claimMsg.error[language]
      }

      setNotification(notification)

      return
    }

    setLoading(true);
    const claimRewards = await api.claimReferralFarming()
    setLoading(false);

    if(!claimRewards) {
      const notification = {
        show: true,
        points: '',
        username: '',
        message: defaultMsg.error[language]
      }

      setNotification(notification)

      return
    }

    google.trackEvent('Referral', 'claim referral rewards', `chat_id: ${tg_info.chat_id}, amount: ${claimRewards.amount}`);

    const notification = {
      show: true,
      points: claimRewards.amount,
      username: '',
      message: claimMsg.success[language]
    }

    setNotification(notification)
    addUserBalance(claimRewards.amount)
    resetReferralBalance()
  }

  return (
    <AppWidthWithContainer className="team">
      <div className="team__content">
        <Title text={pageData.title[language]}></Title>
        <InformationBox
          title={pageData.info.title[language]}
          text={pageData.info.text[language]}
        />
        <Copylink text={process.env.REACT_APP_BOT_URL + '?start=' + tg_info.chat_id} />
        <ReferralFarming loading = {loading} clickHandler = {claimRefRewards} text = {pageData.ref_earnings.description[language]} btnText = {pageData.ref_earnings.btn.claim[language]} coins = {referral_balance} language = {language}/>
        <Partners text={pageData.partners.sum[language]} number={friends.reduce((a, b) => a + b)} />
        <Levels friends = {friends} language = {language} pageData = {pageData}/>
      </div>
    </AppWidthWithContainer>
  );
};

export default Team;
