import React from "react";
import "./style.css";
import CopylinkImage from "./assets/Copylink-img.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import { useGlobalStore } from "stores/global";

export const Copylink = ({ text }) => {

  const chat_id = useGlobalStore(state => state.tg_info.chat_id );

  const [copied, setCopied] = React.useState(false);
  const [copiedText, setCopiedText] = React.useState('klondike?start=' + chat_id);

  React.useEffect(() => {
      if(!copied) return

      setCopiedText('copied')

      let timeout = setTimeout(() => {
          setCopiedText('klondike?start=' + chat_id)
          setCopied(false)
      }, 2000);
      

      return () => {
          clearTimeout(timeout)
      }
  },[copied])


  return (
    <div className="copylink">
      <span className="copylink__text">{copiedText}</span>
      <CopyToClipboard text = {text} onCopy = {() => setCopied(true)}>
      <button className="copylink__btn">
        <img className="copylink__img" src={CopylinkImage} alt="" />
      </button>
      </CopyToClipboard>
    </div>
  );
};
