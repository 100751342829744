import {create} from 'zustand';

export const useGlobalStore = create((set) => ({
    google: null,
    loaded: false,
    api: null,
    twitter: null,
    popup: {
        isOpen: false,
    },
    notification: {
        show: false,
        points: '',
        username: '',
        message: ''
    },

    tg_info: {
        chat_id: null,
        username: null,
        tg_name: null,
        invite_link: null
    },
    user_info: {
        balance: 0,
        referral_balance:0,
        rank: 0,
        friends: [0,0,0],
        avatar: null,
        twitter_connected: false,
        language: 'en'
    },
    tasks: [],
    farm: {
        status: 0,
        time_left: 0,
        coins_farmed: 0,
        total_coins: 0,
        loaded_at: 0
    },
    gift: {
        show: false,
        hash: null,
        amount: null
    },

    gifts_history: [],

    setGiftsHistory(gifts) {
        set((state) => ({
            gifts_history: gifts
        }))
    },

    setGift(gift) {
        set((state) => ({
            gift: {
                show: gift.show,
                hash: gift.hash,
                amount: gift.amount
            }
        }))
    },


    setGoogle(google) {
        set((state) => ({
            google: google
        }))
    },

    setLoaded: (loaded) => {
        set((state) => ({
            loaded: loaded
        }))
    },

    addUserBalance: (balance) => {
        set((state) => ({
            user_info: {
                ...state.user_info,
                balance: state.user_info.balance + balance
            }
        }))
    },

    resetReferralBalance: () => {
        set((state) => ({
            user_info: {
                ...state.user_info,
                referral_balance: 0
            }
        }))
    },

    setFarmInfo: (farm) => {
        set((state) => ({
            farm: {
                status: farm.status,
                time_left: farm.time_left,
                coins_farmed: farm.coins_farmed,
                total_coins: farm.total_coins,
                loaded_at: farm.loaded_at
            }
        }))
    },


    setTgInfo: (user) => {
        set((state) => ({
            tg_info: {
                chat_id: user.id, 
                tg_name: `${user.first_name} ${user.last_name}`,
                username: user.username
            }
        }))
    },

    setApi: (_api) => {
        set({ api: _api });
    },
    
    setUserInfo:(user) => {
        set({
            user_info: {
                balance: user.balance,
                referral_balance: user.referral_balance,
                rank: user.rank,
                friends: [user.level1_friends_count, user.level2_friends_count, user.level3_friends_count], 
                avatar: user.avatar,
                twitter_connected: user.twitter_connect,
                language: user.language
            }
        })
    },

    setTasks: (tasks) => {
        set({ tasks: tasks });
    },

    userTap: () => {
        set((state) => ({
            user_info: {
                ...state.user_info,
                balance: state.user_info.balance + 1
            }
        }));
    },

    setNotification: (notification) => {
        set({ notification: notification });
    },

    setPopup: (open) => {
        set({ popup: {
            isOpen: open
        } });
    }
}))