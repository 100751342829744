import { create } from "zustand";

export const useGameStore = create((set) => ({
    banditsGameStarted: false,
    banditsGameConfigInitialized: false,
    banditsGameAttempts: 0,
    banditsGameHash: null,

    setBanditsGameAttempts: (attempts) => {
        set((state) => ({
            banditsGameAttempts: Number(attempts)
        }))
    },

    setBanditsGameHash: (hash) => {
        set((state) => ({
            banditsGameHash: hash
        }))
    },

    reduceBanditsGameAttempts: () => {
        set((state) => ({
            banditsGameAttempts: state.banditsGameAttempts > 0 ? state.banditsGameAttempts - 1 : 0
        }))
    },

    setBanditsGameStarted: (started) => {
        set((state) => ({
            banditsGameStarted: started
        }))
    },

    setBanditsGameConfig: (config) => {
        set((state) => ({
            banditsGameConfigInitialized: config
        }))
    }
}))