import "./style.css"
import Cross from "./assets/button-Cross.svg"
import { GameRulesSlider } from "components/GameRulesSlider/GameRulesSlider";
import Loader from "components/icons/Loader";

export const GamePopup = ({isOpen, onClose, title, language,rulesData,gameHandler, attempts, loading}) => {
    return (
        <div className={`game-popup ${isOpen ? "game-popup-open" : ""}`}>
            
          <div className="game-popup-content">
          <button className="game-popup-close" onClick={onClose}>
              <img className="game-popup__btn" src={Cross} alt="" />
            </button>
            <span className = "game-popup__title">
              {title}
            </span>
            <span className="game-popup__rules-text">
                    {language === "ru" ? "Правила Игры" : "Game Rules"}
                </span>
            <div className="game-popup__slider">
            <GameRulesSlider data = {rulesData} language = {language}/>
            </div>
            <button className = "game-popup__start-game-button" onClick = {gameHandler}>
                <span className="history-item__desc">{loading ? <Loader fill="#582B02" width={18} height={18} />: language === "ru" ? `Играть (осталось x${attempts})` : `Play (left x${attempts})`}</span>
             </button>
          </div>
          
        </div>
      );
        
}